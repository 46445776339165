import { Component, inject, Renderer2, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, Subscription } from 'rxjs';
import { MenuService } from './menu/menu.service';
import { SidebarComponent } from './sidebar/sidebar.component';
import { TopbarComponent } from './topbar/topbar.component';
import { LayoutService } from './service/layout-service.service';
import { UserInfo } from '@apruv-client/services';
import { CommonModule, DOCUMENT } from '@angular/common';
import { LayoutModule } from './layout.module';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { KeycloakService } from 'keycloak-angular';
import { jwtDecode } from 'jwt-decode';

@Component({
  selector: 'app-layout',
  standalone: true,
  imports: [CommonModule, LayoutModule, BreadcrumbComponent, TopbarComponent],
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss',
})
export class LayoutComponent {
  overlayMenuOpenSubscription: Subscription;

  menuOutsideClickListener: any;
  currenUserSubs!: Subscription;
  @ViewChild(SidebarComponent) appSidebar!: SidebarComponent;

  @ViewChild(TopbarComponent) appTopbar!: TopbarComponent;
  #document = inject(DOCUMENT);

  constructor(private menuService: MenuService, public layoutService: LayoutService, public renderer: Renderer2, public router: Router,
    private keycloak: KeycloakService
  ) {
    this.currenUserSubs = this.layoutService.userInfo$.subscribe(u => {
      if (u) this.handleRedirect(u);
    })
    this.overlayMenuOpenSubscription = this.layoutService.overlayOpen$.subscribe(() => {
      if (!this.menuOutsideClickListener) {
        this.menuOutsideClickListener = this.renderer.listen('document', 'click', (event) => {
          const isOutsideClicked = !(
            this.appTopbar.el.nativeElement.isSameNode(event.target) ||
            this.appTopbar.el.nativeElement.contains(event.target) ||
            this.appTopbar.menuButton.nativeElement.isSameNode(event.target) ||
            this.appTopbar.menuButton.nativeElement.contains(event.target)
          );
          if (isOutsideClicked) {
            this.hideMenu();
          }
        });
      }

      if (this.layoutService.state.staticMenuMobileActive) {
        this.blockBodyScroll();
      }
    });

    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      this.hideMenu();
    });
    this.setLayoutTheme();
  }

  private async setLayoutTheme() {
    const token = await this.getToken();
    try {
      const decodedToken: any = jwtDecode(token!);
      const theme = decodedToken.theme;
      console.log(decodedToken)
      this.layoutService.permissions = decodedToken?.realm_access?.roles

      const themeNumber = this.setTheme(theme) as number;
      if(theme) {
        this.layoutService.setActiveTheme(themeNumber)
        this.layoutService.toggleLightDark(themeNumber)
      }
    } catch (error) {
      console.error("Invalid token", error);
    }
  }

  setTheme(theme: string | number): string | number {
    let themeSelected;
    if(typeof(theme) === 'number') {
      if(theme === 1) themeSelected = 'default'
      else if(theme === 2) themeSelected = 'light';
      else if(theme === 3) themeSelected = 'dark';
    } else {
      if(theme === 'default') themeSelected = 1  
      else if(theme === 'light') themeSelected = 2;
      else if(theme === 'dark') themeSelected = 3;
      this.layoutService.setActiveTheme(themeSelected as number);
    }

    return themeSelected!;
  }

  async getToken() {
    if (await this.keycloak.isLoggedIn()) {
      const token = await this.keycloak.getToken();
      return token;
    } else {
      console.error("User is not logged in.");
      return null;
    }
  }

  private handleRedirect(user: UserInfo) {
    switch (user.RoleID) {
      case 1:
      case 2:
      case 4:
      case 5:
        return;
      case 3:
        return this.router.navigate(['/access-denied']);
      default:
        return this.router.navigate(['/access-denied']);
    }
  }

  blockBodyScroll(): void {
    if (document.body.classList) {
      document.body.classList.add('blocked-scroll');
    } else {
      document.body.className += ' blocked-scroll';
    }
  }

  unblockBodyScroll(): void {
    if (document.body.classList) {
      document.body.classList.remove('blocked-scroll');
    } else {
      document.body.className = document.body.className.replace(new RegExp('(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }
  }

  hideMenu() {
    this.layoutService.state.overlayMenuActive = false;
    this.layoutService.state.staticMenuMobileActive = false;
    this.layoutService.state.menuHoverActive = false;
    this.menuService.reset();
    if (this.menuOutsideClickListener) {
      this.menuOutsideClickListener();
      this.menuOutsideClickListener = null;
    }
    this.unblockBodyScroll();
  }

  get containerClass() {
    return {
      'layout-light': this.layoutService.config.colorScheme === 'light',
      'layout-dark': this.layoutService.config.colorScheme === 'dark',
      'layout-light-menu': this.layoutService.config.menuTheme === 'light',
      'layout-dark-menu': this.layoutService.config.menuTheme === 'dark',
      'layout-light-topbar': this.layoutService.config.topbarTheme === 'light',
      'layout-dark-topbar': this.layoutService.config.topbarTheme === 'dark',
      'layout-transparent-topbar': this.layoutService.config.topbarTheme === 'transparent',
      'layout-overlay': this.layoutService.config.menuMode === 'overlay',
      'layout-static': this.layoutService.config.menuMode === 'static',
      'layout-slim': this.layoutService.config.menuMode === 'slim',
      'layout-slim-plus': this.layoutService.config.menuMode === 'slim-plus',
      'layout-horizontal': this.layoutService.config.menuMode === 'horizontal',
      'layout-reveal': this.layoutService.config.menuMode === 'reveal',
      'layout-drawer': this.layoutService.config.menuMode === 'drawer',
      'layout-static-inactive': this.layoutService.state.staticMenuDesktopInactive && this.layoutService.config.menuMode === 'static',
      'layout-overlay-active': this.layoutService.state.overlayMenuActive,
      'layout-mobile-active': this.layoutService.state.staticMenuMobileActive,
      'p-input-filled': this.layoutService.config.inputStyle === 'filled',
      'p-ripple-disabled': !this.layoutService.config.ripple,
      'layout-sidebar-active': this.layoutService.state.sidebarActive,
      'layout-sidebar-anchored': this.layoutService.state.anchored
    };
  }

  ngOnDestroy() {
    if (this.overlayMenuOpenSubscription) {
      this.overlayMenuOpenSubscription.unsubscribe();
    }

    if (this.menuOutsideClickListener) {
      this.menuOutsideClickListener();
    }
  }
}
