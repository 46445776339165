import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { AppointmentDetailsResponse, ApruvRateService, ReviewService } from '@apruv-client/services';
import { catchError, mergeMap, throwError } from 'rxjs';
import { ReviewOldComponent } from './review-old/review-old.component';
import { LoadingSpinnerComponent } from '../shared/components/loader/loading-spinner.component';
import { ToastModule } from 'primeng/toast';
import { KeycloakService } from 'keycloak-angular';
import { jwtDecode } from 'jwt-decode';
import {LayoutService} from "../layout/service/layout-service.service";

@Component({
  selector: 'app-review',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, ButtonModule, RouterOutlet, ReviewOldComponent, LoadingSpinnerComponent, ToastModule],
  templateUrl: './review.component.html',
  styleUrl: './review.component.scss',
})
export class ReviewComponent implements OnInit {
  showOldReviewComponent = false;
  newReviewComponentPaths = ['add', 'negative', 'payment', 'tip-tech', 'tipping', 'thank-you', 'done'];

  constructor(private apruvRateService: ApruvRateService,
              private reviewService: ReviewService,
              private route: ActivatedRoute,
              private router: Router,
              private keycloak: KeycloakService
  ) {
    const urlPath = this.router.url;
    const subPaths = urlPath.split('/').filter(Boolean);
    this.showOldReviewComponent = !subPaths.some(subPath => this.newReviewComponentPaths.includes(subPath));
  }

  ngOnInit(): void {
    const appointmentId = this.route.snapshot?.children[0]?.params['appointmentId'];
    const companyName = this.route.snapshot?.children[0]?.params['companyName'];
    this.reviewService.appointmentId = appointmentId;
    this.reviewService.companyName = companyName;
    // this.getAppointmentDetails(appointmentId);
  }

  // getAppointmentDetails(appointmentId: string) {
  //   console.log(this.reviewService.oldReview)
  //   this.reviewService.getAppointmentDetails(appointmentId, 'v1').pipe(
  //     mergeMap((appointment: AppointmentDetailsResponse) => {
  //       if(appointment) this.reviewService.appointmentDetails = appointment;
  //       return this.apruvRateService.getConfig(appointment.appointment_details.CompanyID, appointment.appointment_details.LocationID)
  //     }),
  //     catchError((error) => throwError(error))
  //   ).subscribe({
  //     next: (res) => {
  //       this.apruvRateService.rateConfig = res;
  //     },
  //     error: (error) => {
  //       console.log(error);
  //     }
  //   })
  // }

  // async initializeToken() {
  //   try {
  //     const token = await this.keycloak.getToken();
  //     const decodedToken: any = jwtDecode(token!);
  //     this.reviewService.superuser = decodedToken?.realm_access?.roles?.includes('super_user');
  //   } catch (error) {
  //     console.error('Error fetching initial data:', error);
  //   }
  // }
}
