<div class="flex align-items-center justify-content-between px-3">
    <img src="./assets/images/verify-logo.svg" alt="">

    <img class="cursor-pointer" src="./assets/images/verify-menu.svg" alt="" (click)="sidebarVisible = true">
</div>

<p-sidebar #sidebarRef [(visible)]="sidebarVisible" styleClass="w-full surface-ground border-none">
    <ng-template pTemplate="headless">
        <div class="flex flex-column h-full">
            <div class="flex align-items-center justify-content-between pdn-t pdn-x flex-shrink-0">
                <span class="inline-flex align-items-center gap-2">
                    <img src="./assets/images/verify-logo.svg" alt="">
                </span>
                <span>
                    <i class="pi pi-times cursor-pointer" style="font-size: 1.5rem" (click)="closeCallback($event)" #close></i>
                </span>
            </div>
            <div class="overflow-y-auto">
                <ul class="list-none p-3 py-6 m-0 overflow-hidden">
                    <li>
                        <a pRipple class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple" (click)="goto('')">
                            <i class="pi pi-home mr-3 text-color-secondary" style="font-size: 1.25rem"></i>
                            <span class="font-medium text-xl text-white">Home</span>
                        </a>
                    </li>
                    <li>
                        <a
                            pRipple
                            pStyleClass="@next"
                            enterClass="hidden"
                            enterActiveClass="slidedown"
                            leaveToClass="hidden"
                            leaveActiveClass="slideup"
                            class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple"
                        >
                            <i class="pi pi-user mr-3 text-color-secondary" style="font-size: 1.25rem"></i>
                            <span class="font-medium text-xl text-white">Profile</span>
                            <i class="pi pi-chevron-down ml-auto text-color-secondary"></i>
                        </a>
                        <ul class="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out sub-li">
                            <li>
                                <a pRipple class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple" (click)="goto('/profile?tab=0')">
                                    <i class="pi pi-user-edit mr-3 text-color-secondary" style="font-size: 1.25rem"></i>
                                    <span class="font-medium text-xl text-white">User Profile</span>
                                </a>
                            </li>
                            <li>
                                <a pRipple class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple" (click)="goto('/profile?tab=1')">
                                    <i class="pi pi-lock mr-3 text-color-secondary" style="font-size: 1.25rem"></i>
                                    <span class="font-medium text-xl text-white">Password</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a pRipple class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple" (click)="signout()">
                            <i class="pi pi-sign-out mr-3 text-color-secondary" style="font-size: 1.25rem"></i>
                            <span class="font-medium text-xl text-white">Sign out</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </ng-template>
</p-sidebar>