import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { TechnicianLayoutService } from '../service/technician-layout.service';
import { LayoutService } from '../../layout/service/layout-service.service';

@Component({
  selector: 'apruv-header',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent {

  constructor(private router: Router, public technicianLayout: TechnicianLayoutService, public layoutService: LayoutService) {}

  profile() {
    this.router.navigateByUrl('/technician/profile');
  }

  notification() {
    this.router.navigateByUrl('/notifications');
  }
}
