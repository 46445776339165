import {Component, OnInit} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from '@angular/router';
import { KeycloakAngularModule } from 'keycloak-angular';
import {GooglePayService} from "./shared/services/google-pay.service";

@Component({
  standalone: true,
  imports: [
    RouterModule,
    KeycloakAngularModule,
    FormsModule,
    ReactiveFormsModule
  ],
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  constructor(private googlePayService: GooglePayService) {}
  title = 'web';

  ngOnInit() {}
}
