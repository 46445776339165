<input class="mt-2 ml-5 w-17rem" type="text" pInputText placeholder="Search"  [(ngModel)]="search" (keyup)="searchTab()"/>
<ul class="layout-menu flex flex-column">
    @for (item of model; let i = $index; track $index) {
        @if (!item.separator) {
            <li apruv-menuitem [item]="item" [index]="i" [root]="true"></li>
        }
        @else {
            <li class="menu-separator"></li>
        }
    }
</ul>