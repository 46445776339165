import { Component, ElementRef, ViewChild, OnInit, ChangeDetectorRef, inject } from '@angular/core';
import { LayoutService } from '../service/layout-service.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CompanyList, CompanySave, FrontendCompanyService, HLCompanyList, JwtPayload } from '@apruv-client/services';
import { MenuItem } from 'primeng/api'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { KeycloakService } from 'keycloak-angular';
import { Subscription } from 'rxjs';
import { environment } from '@env';
import { CommonModule, DOCUMENT } from '@angular/common';
import { LayoutModule } from '../layout.module';
import { SidebarComponent } from '../sidebar/sidebar.component';

@Component({
  selector: 'apruv-topbar',
  standalone: true,
  imports: [CommonModule, LayoutModule, SidebarComponent],
  templateUrl: 'topbar.component.html',
  styleUrls: ["./topbar.component.scss"]
})
export class TopbarComponent implements OnInit {
  @ViewChild('menubutton') menuButton!: ElementRef;

  menuItems: MenuItem[] = [
    { label: 'Profile', routerLink: ['/profile'], icon: 'pi pi-user' },
    {
      label: 'Apruv App', command: () => {
        if (window.location.origin.includes('localhost')) {
          window.location.href = 'http://localhost:4200/dashboard/balance'; //if the apruv app is running on port: 4200
        } else {
          window.location.href = `${environment.keyCloakUrl}/dashboard/balance`;
        }
      }, icon: 'pi pi-arrow-circle-right'
    },
  ]

  isTechnicianDashboardRoute = false;

  activeItem!: number;
  currenUserSubs!: Subscription;

  constructor(
    public layoutService: LayoutService,
    public el: ElementRef,
  ) {}

  ngOnInit() {
  }

  onMenuButtonClick() {
    this.layoutService.onMenuToggle();
  }

  onSidebarButtonClick() {
    this.layoutService.showSidebar();
  }

  onConfigButtonClick() {
    this.layoutService.showConfigSidebar();
  }
}