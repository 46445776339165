import { Component, ElementRef, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarModule } from 'primeng/sidebar';
import { Sidebar } from 'primeng/sidebar';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { StyleClassModule } from 'primeng/styleclass';
import { Router } from '@angular/router';
import { LayoutService } from '../../layout/service/layout-service.service';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, SidebarModule, ButtonModule, RippleModule, StyleClassModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent {
  @ViewChild('sidebarRef') sidebarRef!: Sidebar;
  @ViewChild('close') closeButton!: ElementRef;

  closeCallback(e: any): void {
    this.sidebarRef.close(e);
  }

  sidebarVisible: boolean = false;

  constructor(private router: Router, public layoutService: LayoutService, private keycloak: KeycloakService) {}

  goto(route: string){
    this.closeButton.nativeElement.click();
    this.router.navigateByUrl(`/verify${route}`);
  }

  signout() {
    this.keycloak.logout();
    this.layoutService.isRedirect = false;
  }
}
