<div class="layout-topbar">
    <div class="topbar-start">
        <button #menubutton type="button" class="topbar-menubutton p-link p-trigger transition-duration-300" (click)="onMenuButtonClick()">
            <i class="pi pi-bars"></i>
        </button>
    </div>
    <div class="layout-topbar-menu-section">
        <apruv-sidebar></apruv-sidebar>
    </div>
    <div class="topbar-end">
        <ul class="topbar-menu">
            <li class="topbar-item">
                <p-menu #settingsMenu [model]="menuItems" [popup]="true" appendTo="body"></p-menu>
            </li>
        </ul>
    </div>
</div>
