import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Subscription } from 'rxjs';
import { UserInfo } from '@apruv-client/services';
import { LayoutService } from '../service/layout-service.service';
import { LayoutModule } from '../layout.module';
import { MenuitemComponent } from './menuitem.component';

const MENU = [
  {
    visible: true,
    items: [
      {
        label: 'Getting Started',
        icon: 'pi pi-fw pi-graduation-cap',
        routerLink: ['/getting-started'],
        visible: true,
        permission: 'super_user',
      },
      {
        label: 'Dashboard',
        icon: 'pi pi-fw pi-home',
        routerLink: ['/dashboard'],
        visible: true,
        permission: 'super_user',
      },
      {
        label: 'Apruv Rate',
        icon: 'pi pi-fw pi-star',
        routerLink: ['/apruv-rate'],
        visible: true,
        permission: 'rate',
        items: [
          {
            label: 'Ratings',
            routerLink: ['/apruv-rate'],
            visible: true,
            permission: 'rate.ratings',
          },
          {
            label: 'Feedback',
            routerLink: ['/apruv-rate/feedback'],
            visible: true,
            permission: 'rate.feedback',
          },
          {
            label: 'Reputation',
            routerLink: ['/apruv-rate/reputation'],
            visible: true,
            permission: 'rate.reputation',
          },
          {
            label: 'Configuration',
            routerLink: ['/apruv-rate/rate-settings'],
            visible: true,
            permission: 'rate.configuration.read',
          }
        ]
      },
      {
        label: 'Apruv Actions',
        icon: 'pi pi-fw pi-users',
        routerLink: ['/apruv-actions/referral'],
        visible: true,
        permission: 'actions',
        items: [
          {
            label: 'Referral',
            routerLink: ['/apruv-actions/referral'],
            visible: true,
            permission: 'actions.referral',
          },
          {
            label: 'Configuration',
            routerLink: ['/apruv-actions/configuration'],
            visible: true,
            permission: 'actions.configuration',
          },
        ]
      },
      {
        label: 'Apruv Align',
        icon: 'pi pi-fw pi-user',
        routerLink: ['/apruv-align/manager'],
        visible: true,
        permission: 'align',
        items: [
          {
            label: 'Executive',
            routerLink: ['/apruv-align/executive'],
            permission: 'align.executive',
            visible: true,
          },
          {
            label: 'Sales',
            routerLink: ['/apruv-align/sales'],
            permission: 'align.sales',
            visible: true,
          },
          {
            label: 'Manager',
            routerLink: ['/apruv-align/manager'],
            permission: 'align.manager',
            visible: true,
          },
          {
            label: 'Configuration',
            routerLink: ['/apruv-align/configuration'],
            permission: 'align.configuration.read',
            visible: true,
          },
          {
            label: 'Average Time on Site',
            routerLink: ['/apruv-align/manager/average-time-on-site'],
            permission: 'align.configuration.read',
            visible: false,
          },
          {
            label: 'Average Driving Score',
            routerLink: ['/apruv-align/manager/driving-score'],
            permission: 'align.configuration.read',
            visible: false,
          },
          {
            label: 'Reservices',
            routerLink: ['/apruv-align/manager/reservices'],
            permission: 'align.configuration.read',
            visible: false,
          },
          {
            label: 'Average Tip',
            routerLink: ['/apruv-align/manager/average-tip'],
            permission: 'align.configuration.read',
            visible: false,
          },
          {
            label: 'Total Tips',
            routerLink: ['/apruv-align/manager/total-tips'],
            permission: 'align.configuration.read',
            visible: false,
          },
          {
            label: 'CSAT',
            routerLink: ['/apruv-align/manager/csat'],
            permission: 'align.configuration.read',
            visible: false,
          },
          {
            label: 'Apruv Ratings Count',
            routerLink: ['/apruv-align/manager/apruv-ratings-count'],
            permission: 'align.configuration.read',
            visible: false,
          },
          {
            label: 'Negative Ratings',
            routerLink: ['/apruv-align/manager/negative-rating-count'],
            permission: 'align.configuration.read',
            visible: false,
          },
          {
            label: 'Completed Services',
            routerLink: ['/apruv-align/manager/completed-services'],
            permission: 'align.configuration.read',
            visible: false,
          },
          {
            label: 'Avg Revenue Production',
            routerLink: ['/apruv-align/manager/revenue-production'],
            permission: 'align.configuration.read',
            visible: false,
          },
          {
            label: 'Avg Proof of Service',
            routerLink: ['/apruv-align/manager/proof-of-service'],
            permission: 'align.configuration.read',
            visible: false,
          },
          {
            label: 'Avg Productivity',
            routerLink: ['/apruv-align/manager/productivity'],
            permission: 'align.configuration.read',
            visible: false,
          },
        ]
      },
      {
        label: 'Apruv Verify',
        icon: 'pi pi-fw pi-verified',
        routerLink: ['/apruv-verify/overview'],
        permission: 'verify',
        visible: true,
        items: [
          {
            label: 'Overview',
            routerLink: ['/apruv-verify/overview'],
            permission: 'verify.overview',
            visible: true,
          },
          {
            label: 'Reports',
            routerLink: ['/apruv-verify/reports'],
            permission: 'verify.reports',
            visible: true,
          },
          {
            label: 'Audit',
            routerLink: ['/apruv-verify/audit'],
            permission: 'verify.audit',
            visible: true,
          },
          {
            label: 'Configuration',
            routerLink: ['/apruv-verify/configuration'],
            permission: 'verify.configuration.read',
            visible: true,
          },
          {
            label: 'Add Verify Flow',
            routerLink: ['/apruv-verify/add-verify-flow'],
            permission: 'verify.configuration.write',
            visible: false,
          }
        ]
      }
    ]
  },
  {
    visible: true,
    items: [
      {
        label: 'Settings',
        icon: 'pi pi-fw pi-cog',
        routerLink: ['/settings'],
        permission: 'settings',
        visible: true,
        items: [
          {
            label: 'Profile',
            routerLink: ['/settings'],
            permission: 'settings.user-profile',
            visible: true,
          },
          {
            label: 'Billing',
            routerLink: ['/settings/billing'],
            permission: 'settings.billing.read',
            visible: true,
          },
          {
            label: 'Company',
            routerLink: ['/settings/company'],
            permission: 'settings.company',
            visible: true,
          },
          {
            label: 'Users',
            routerLink: ['/settings/users'],
            permission: 'settings.users.read',
            visible: true,
          },
          {
            label: 'Integrations',
            routerLink: ['/settings/integrations'],
            permission: 'settings.integrations.read',
            visible: true,
          }
        ]
      },
      {
        label: 'Operations',
        icon: 'pi pi-fw pi-align-justify',
        routerLink: ['/company'],
        permission: 'operations',
        visible: true,
        items: [
          {
            label: 'Configuration',
            routerLink: ['/company'],
            permission: 'operations.configuration.read',
            visible: true,
          },
        ]
      },
      {
        label: 'Applications',
        icon: 'pi pi-fw pi-th-large',
        routerLink: [''],
        permission: 'applications',
        visible: true,
        items: [
          {
            label: 'Field',
            routerLink: ['/technician'],
            permission: 'applications',
            visible: true,
            external: true,
          },
          {
            label: 'Verify',
            routerLink: ['/verify'],
            permission: 'applications',
            visible: true,
            external: true,
          },
        ]
      },
      {
        label: 'Sign Out',
        icon: 'pi pi-fw pi-sign-out',
        routerLink: ['/payout'],
        isSignOut: true,
      }
    ]
  }
]

@Component({
  selector: 'apruv-menu',
  standalone: true,
  imports: [CommonModule, LayoutModule, MenuitemComponent],
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.scss',
})
export class MenuComponent {
  model: any[] = [];
  private userInfoSubscription: Subscription;
  currentUser!: UserInfo;
  search: string = '';

  constructor(
    private layoutService: LayoutService
  ) {
    this.userInfoSubscription = new Subscription();
  }

  ngOnInit() {
    const permissions: string[] = this.layoutService.permissions as string[];
    this.model = this.filterMenu(MENU, permissions);
    this.userInfoSubscription = this.layoutService.userInfo$.subscribe(res => {
      this.currentUser = res!;
    })
  }

  private filterMenu(menu: any[], permissions: string[]): any[] {
    const isSuperUser = permissions.includes("super_user"); // Check if 'super_user' is in the permissions array

    const hasPermission = (requiredPermission: string | undefined): boolean => {
      if (!requiredPermission) return true; // If no permission, always visible
      if (isSuperUser) return true; // Super user gets access to everything
      return permissions.some(permission => permission.startsWith(requiredPermission)); // Check for partial match
    };

    return menu
      .map(group => {
        const filteredItems = group.items
          .map((item: any) => {
            if (item.items) {
              item.items = item.items.filter((child: any) => hasPermission(child.permission));
            }

            const isItemVisible = hasPermission(item.permission);
            const hasVisibleChildren = item.items && item.items.length > 0;

            return isItemVisible || hasVisibleChildren || !item.permission ? { ...item } : null;
          })
          .filter(Boolean);

        return filteredItems.length > 0 ? { ...group, items: filteredItems } : null;
      })
      .filter(Boolean);
  }

  ngOnDestroy(): void {
    this.userInfoSubscription.unsubscribe();
  }

  searchTab(){
    if(this.search){
      this.model.forEach((element)=>{
        element.items = element.items.filter((value: any) => {
          if(value.items){
            value.items = value.items.filter((subElement: any)=>{
              return subElement.label.toLowerCase().includes(this.search.toLowerCase())
            })
            return value.label.toLowerCase().includes(this.search.toLowerCase()) || value.items.length;
          } else{
            return value.label.toLowerCase().includes(this.search.toLowerCase());
          }
        });
      })
    } else{
      this.model = this.filterMenu(MENU, this.layoutService.permissions as string[]);
    }
  }
}
