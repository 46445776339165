<footer class="footer-container flex justify-content-between align-items-center p-3">
  <div class="footer-item flex flex-column align-items-center cursor-pointer" (click)="selectTab('home')">
    <i class="pi pi-home" [ngClass]="{'footer-icon-active': active === 'home'}"></i>
    <span [ngClass]="{'text-active': active === 'home'}">Home</span>
  </div>
  @if (techLayoutService.isTip || techLayoutService.techOverview?.Transaction?.TotalTransactions) {
    <div class="footer-item flex flex-column align-items-center cursor-pointer" (click)="selectTab('wallet')">
      <i class="pi pi-wallet" [ngClass]="{'footer-icon-active': active === 'wallet'}"></i>
      <span [ngClass]="{'text-active': active === 'wallet'}">Wallet</span>
    </div>
  }
  <div class="footer-item flex flex-column align-items-center cursor-pointer" (click)="selectTab('feedback')">
    <i class="pi pi-check-square" [ngClass]="{'footer-icon-active': active === 'feedback'}"></i>
    <span [ngClass]="{'text-active': active === 'feedback'}">Feedback</span>
  </div>
  @if (layoutService.superuser) {
    <div class="footer-item flex flex-column align-items-center cursor-pointer" (click)="selectTab('rank')">
      <i class="pi pi-trophy" [ngClass]="{'footer-icon-active': active === 'rank'}"></i>
      <span [ngClass]="{'text-active': active === 'rank'}">Rank</span>
    </div>
    <div class="footer-item flex flex-column align-items-center cursor-pointer" (click)="selectTab('service')">
      <i class="pi pi-truck" [ngClass]="{'footer-icon-active': active === 'service'}"></i>
      <span [ngClass]="{'text-active': active === 'service'}">Service</span>
    </div>
  }
</footer>
