<div class="w-full h-screen p-4 grid">
    <div class="col-6 flex align-items-center justify-content-center">
        <div class="steps-container">
            @if (layoutService.config.colorScheme === 'dark') {
                <svg id="Layer_1" class="m-0 p-0 cursor-pointer" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" height="20px" width="148px" viewBox="0 50 250 48">
                  <defs>
                    <style>
                      .cls-1 {
                          fill: #fff;
                      }
                    </style>
                  </defs>
                  <path class="cls-1" d="M45.15,96.7c-.45,.66-1.05,.99-1.79,.99H2.19c-.78,0-1.38-.33-1.79-.98-.45-.66-.52-1.33-.19-2.03l1.92-4.48c.16-.41,.42-.74,.77-.98,.35-.24,.75-.37,1.21-.37h28.81l-1.85-4.46-8.28-19.81-8.28,19.81c-.16,.41-.42,.73-.77,.96-.35,.23-.75,.34-1.21,.34H7.32c-.78,0-1.4-.33-1.85-.99-.41-.62-.47-1.3-.19-2.04l12.55-29.47c.16-.45,.42-.79,.77-1.02,.35-.23,.75-.34,1.21-.34h5.93c.95,0,1.61,.45,1.98,1.36l17.62,41.48c.29,.66,.23,1.34-.19,2.04Z"/>
                  <path class="cls-1" d="M88.86,56.02c3,2.84,4.51,6.61,4.51,11.31s-1.51,8.47-4.51,11.31c-2.93,2.76-6.88,4.14-11.87,4.14h-11.81v12.61c0,.58-.22,1.08-.65,1.52-.43,.43-.93,.65-1.5,.65h-4.66c-.57,0-1.08-.22-1.51-.65-.43-.43-.64-.94-.64-1.52v-19.16c0-.58,.22-1.08,.65-1.52,.43-.43,.94-.65,1.52-.65h18.3c2.76,0,4.74-.59,5.93-1.78,1.19-1.07,1.79-2.71,1.79-4.92s-.6-3.77-1.79-4.92c-1.28-1.19-3.26-1.78-5.93-1.78h-18.3c-.58,0-1.08-.22-1.52-.65-.43-.44-.65-.94-.65-1.53v-4.48c0-.58,.22-1.09,.65-1.52,.43-.44,.94-.65,1.51-.65h18.64c4.9,0,8.85,1.4,11.85,4.2Z"/>
                  <path class="cls-1" d="M143.75,94.23c.45,.74,.47,1.48,.06,2.23-.41,.74-1.03,1.11-1.85,1.11h-5.63c-.78,0-1.38-.33-1.79-.99l-9.42-14.9h-11.04v13.72c0,.58-.22,1.08-.65,1.52-.43,.43-.93,.65-1.5,.65h-4.66c-.57,0-1.08-.22-1.51-.65-.43-.43-.64-.94-.64-1.52v-20.39c0-.58,.22-1.08,.65-1.52,.43-.43,.94-.65,1.52-.65h19.04c3.63,0,6.04-1.02,7.23-3.06,.41-.65,.62-1.59,.62-2.82,0-1.92-.64-3.43-1.92-4.53-1.32-1.18-3.22-1.77-5.69-1.77h-19.29c-.58,0-1.08-.22-1.52-.65-.43-.44-.65-.94-.65-1.53v-4.48c0-.58,.22-1.09,.65-1.52,.43-.44,.94-.65,1.51-.65h19.81c4.77,0,8.62,1.36,11.54,4.08,3,2.72,4.51,6.41,4.51,11.07,0,6.1-2.56,10.28-7.67,12.55-.16,.08-.33,.15-.49,.22s-.35,.13-.55,.22l9.33,14.28Z"/>
                  <path class="cls-1" d="M172.56,89.09c.49,.17,.89,.44,1.17,.84,.29,.39,.43,.83,.43,1.33v4.76c0,.66-.27,1.22-.8,1.67-.41,.33-.87,.49-1.36,.49-.21,0-.35-.02-.43-.06-3.83-.74-7.13-2.51-9.89-5.32-3.71-3.75-5.56-8.74-5.56-14.96v-23.8c0-.62,.22-1.14,.65-1.58,.43-.43,.94-.65,1.51-.65h4.76c.62,0,1.14,.22,1.58,.65,.43,.43,.65,.96,.65,1.58v23.92c0,3.92,1.11,6.9,3.34,8.96,1.15,1.07,2.47,1.79,3.96,2.16Zm21.33-37.28c.62,0,1.14,.22,1.58,.65,.43,.43,.65,.96,.65,1.58v23.8c0,6.22-1.85,11.21-5.56,14.96-2.76,2.8-6.06,4.57-9.89,5.32-.08,.04-.23,.06-.43,.06-.49,0-.95-.16-1.36-.49-.54-.41-.8-.99-.8-1.73v-4.76c0-.45,.14-.88,.43-1.27,.29-.39,.68-.67,1.17-.84,1.57-.45,2.88-1.17,3.96-2.16,2.23-1.98,3.34-4.97,3.34-8.96v-23.92c0-.62,.22-1.14,.65-1.58,.43-.43,.96-.65,1.58-.65h4.7Z"/>
                  <path class="cls-1" d="M249.58,52.81c.45,.62,.54,1.3,.25,2.04l-16.94,41.85c-.21,.45-.49,.79-.83,1.02-.35,.23-.75,.34-1.21,.34h-5.44c-1.03,0-1.71-.45-2.04-1.36l-16.94-41.85c-.33-.7-.27-1.38,.18-2.04,.45-.66,1.05-.99,1.79-.99h20.65c.58,0,1.08,.22,1.52,.65,.43,.43,.65,.96,.65,1.58v4.51c0,.62-.22,1.14-.65,1.58-.43,.43-.94,.65-1.52,.65h-10.39l9.46,23.8,12.42-31.4c.33-.91,1.01-1.36,2.04-1.36h5.19c.78,0,1.38,.33,1.79,.99Z"/>
                </svg>
            } 
            @else {
                <svg id="Layer_1" class="m-0 p-0 cursor-pointer" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" height="20px" width="148px" viewBox="0 50 250 48">
                    <defs>
                      <style>
                        .cls-1 {
                            fill: #050520;
                        }
                      </style>
                    </defs>
                    <path class="cls-1" d="M45.15,96.7c-.45,.66-1.05,.99-1.79,.99H2.19c-.78,0-1.38-.33-1.79-.98-.45-.66-.52-1.33-.19-2.03l1.92-4.48c.16-.41,.42-.74,.77-.98,.35-.24,.75-.37,1.21-.37h28.81l-1.85-4.46-8.28-19.81-8.28,19.81c-.16,.41-.42,.73-.77,.96-.35,.23-.75,.34-1.21,.34H7.32c-.78,0-1.4-.33-1.85-.99-.41-.62-.47-1.3-.19-2.04l12.55-29.47c.16-.45,.42-.79,.77-1.02,.35-.23,.75-.34,1.21-.34h5.93c.95,0,1.61,.45,1.98,1.36l17.62,41.48c.29,.66,.23,1.34-.19,2.04Z"/>
                    <path class="cls-1" d="M88.86,56.02c3,2.84,4.51,6.61,4.51,11.31s-1.51,8.47-4.51,11.31c-2.93,2.76-6.88,4.14-11.87,4.14h-11.81v12.61c0,.58-.22,1.08-.65,1.52-.43,.43-.93,.65-1.5,.65h-4.66c-.57,0-1.08-.22-1.51-.65-.43-.43-.64-.94-.64-1.52v-19.16c0-.58,.22-1.08,.65-1.52,.43-.43,.94-.65,1.52-.65h18.3c2.76,0,4.74-.59,5.93-1.78,1.19-1.07,1.79-2.71,1.79-4.92s-.6-3.77-1.79-4.92c-1.28-1.19-3.26-1.78-5.93-1.78h-18.3c-.58,0-1.08-.22-1.52-.65-.43-.44-.65-.94-.65-1.53v-4.48c0-.58,.22-1.09,.65-1.52,.43-.44,.94-.65,1.51-.65h18.64c4.9,0,8.85,1.4,11.85,4.2Z"/>
                    <path class="cls-1" d="M143.75,94.23c.45,.74,.47,1.48,.06,2.23-.41,.74-1.03,1.11-1.85,1.11h-5.63c-.78,0-1.38-.33-1.79-.99l-9.42-14.9h-11.04v13.72c0,.58-.22,1.08-.65,1.52-.43,.43-.93,.65-1.5,.65h-4.66c-.57,0-1.08-.22-1.51-.65-.43-.43-.64-.94-.64-1.52v-20.39c0-.58,.22-1.08,.65-1.52,.43-.43,.94-.65,1.52-.65h19.04c3.63,0,6.04-1.02,7.23-3.06,.41-.65,.62-1.59,.62-2.82,0-1.92-.64-3.43-1.92-4.53-1.32-1.18-3.22-1.77-5.69-1.77h-19.29c-.58,0-1.08-.22-1.52-.65-.43-.44-.65-.94-.65-1.53v-4.48c0-.58,.22-1.09,.65-1.52,.43-.44,.94-.65,1.51-.65h19.81c4.77,0,8.62,1.36,11.54,4.08,3,2.72,4.51,6.41,4.51,11.07,0,6.1-2.56,10.28-7.67,12.55-.16,.08-.33,.15-.49,.22s-.35,.13-.55,.22l9.33,14.28Z"/>
                    <path class="cls-1" d="M172.56,89.09c.49,.17,.89,.44,1.17,.84,.29,.39,.43,.83,.43,1.33v4.76c0,.66-.27,1.22-.8,1.67-.41,.33-.87,.49-1.36,.49-.21,0-.35-.02-.43-.06-3.83-.74-7.13-2.51-9.89-5.32-3.71-3.75-5.56-8.74-5.56-14.96v-23.8c0-.62,.22-1.14,.65-1.58,.43-.43,.94-.65,1.51-.65h4.76c.62,0,1.14,.22,1.58,.65,.43,.43,.65,.96,.65,1.58v23.92c0,3.92,1.11,6.9,3.34,8.96,1.15,1.07,2.47,1.79,3.96,2.16Zm21.33-37.28c.62,0,1.14,.22,1.58,.65,.43,.43,.65,.96,.65,1.58v23.8c0,6.22-1.85,11.21-5.56,14.96-2.76,2.8-6.06,4.57-9.89,5.32-.08,.04-.23,.06-.43,.06-.49,0-.95-.16-1.36-.49-.54-.41-.8-.99-.8-1.73v-4.76c0-.45,.14-.88,.43-1.27,.29-.39,.68-.67,1.17-.84,1.57-.45,2.88-1.17,3.96-2.16,2.23-1.98,3.34-4.97,3.34-8.96v-23.92c0-.62,.22-1.14,.65-1.58,.43-.43,.96-.65,1.58-.65h4.7Z"/>
                    <path class="cls-1" d="M249.58,52.81c.45,.62,.54,1.3,.25,2.04l-16.94,41.85c-.21,.45-.49,.79-.83,1.02-.35,.23-.75,.34-1.21,.34h-5.44c-1.03,0-1.71-.45-2.04-1.36l-16.94-41.85c-.33-.7-.27-1.38,.18-2.04,.45-.66,1.05-.99,1.79-.99h20.65c.58,0,1.08,.22,1.52,.65,.43,.43,.65,.96,.65,1.58v4.51c0,.62-.22,1.14-.65,1.58-.43,.43-.94,.65-1.52,.65h-10.39l9.46,23.8,12.42-31.4c.33-.91,1.01-1.36,2.04-1.36h5.19c.78,0,1.38,.33,1.79,.99Z"/>
                  </svg>
            }
            <div class="flex-grow-1 mt-6">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
    <div class="col-6">
        <div class="bg-primary border-round-3xl h-full w-full preview-container">
          <p-carousel 
            [style]="{'width': '100%'}"
            [value]="items" 
            [numVisible]="1" 
            [numScroll]="1" 
            [circular]="true"
            [showNavigators]="false"
            [autoplayInterval]="15000"
            [showNavigators]="true"
            [circular]="true"
            (onPage)="onPageChange($event)"
            [page]="activePage">
            <ng-template let-item pTemplate="item">
              <div class="flex flex-column align-items-center justify-content-center image-preview-container">
                  @if (activePage === 0) {
                    <img style="width: 440px; height: auto;" src="../../assets/images/apruv-verify-preview.svg" alt="" srcset="">
                  } @else if (activePage === 1) {
                    <img style="width: 440px; height: auto;" src="../../assets/images/apruv-align-preview.svg" alt="" srcset="">
                  } @else {
                    <img style="width: 440px; height: auto;" src="../../assets/images/apruv-rate-actions-preview.svg" alt="" srcset="">
                  }
              </div>
              <div class="mt-8">
                <h4 class="text-center mb-2">{{item.title}}</h4>
                <h6 class="text-center font-normal">{{item.subtitle}}</h6>
              </div>
            </ng-template>
          </p-carousel>
        </div>
    </div>
</div>
