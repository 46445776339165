import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { LayoutService } from '../layout/service/layout-service.service';
import { InputTextModule } from 'primeng/inputtext';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CarouselModule, CarouselPageEvent } from 'primeng/carousel';
@Component({
  selector: 'app-onboarding',
  standalone: true,
  imports: [CommonModule, RouterOutlet, InputTextModule, ReactiveFormsModule, CarouselModule],
  templateUrl: './onboarding.component.html',
  styleUrl: './onboarding.component.scss',
})
export class OnboardingComponent implements OnInit {
  onboardingForm: FormGroup;
  activePage = 0;
  items = [
    {
      title: 'Apruv™ Verify',
      subtitle: 'Elevate Customer Trust and Reduce Attrition'
    },
    {
      title: 'Apruv™ Align',
      subtitle: 'Leaderboard your staff. Drive performance.'
    },
    {
      title: 'Apruv™ Rate + Actions',
      subtitle: 'Automate Reviews, Rewards, and Referrals.'
  }
  ];
  
  constructor(public layoutService: LayoutService, private formBuilder: FormBuilder) {
    this.onboardingForm = this.formBuilder.group({
      name: ['Name: John Doe'],
      phone: ['Phone Number: 801-123-124'],
      address: ['Address: 1234 Street'],
      email: ['Email: john.d@apruv.io']
    })
  }
  ngOnInit(): void {
      
  }

  onPageChange(event: CarouselPageEvent) {
    console.log(event)
    this.activePage = event.page!;
  }
}
