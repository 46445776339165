import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpErrorResponse, HttpParams } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class ApiConfigInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        
        if (request.url.includes('api/frontend/v1') && !request.headers.has('no-override')) {
            if(!request.headers.keys().includes('company-id')){
                request = request.clone({
                    headers: request.headers.set('company-id', String(sessionStorage.getItem('companyId')))
                });
            }

            if(!request.headers.keys().includes('location-id')){
                request = request.clone({
                    headers: request.headers.set('location-id', String(sessionStorage.getItem('locationId')))
                });
            }
    
        }

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                return event;
            }),
            catchError((error: HttpErrorResponse) => throwError(error)));
    }
}
