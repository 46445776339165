<header class="header-container flex justify-content-between align-items-center p-3">
  <div *ngIf="technicianLayout.profileDetails$ | async as profileData" class="profile-pic-container" (click)="profile()">
    @if (profileData.profile_image) {
      <img [src]="profileData.profile_image" alt="Profile Picture" class="profile-pic">
    }
    @else {
        <div class="profile-image flex align-items-center justify-content-center surface-ground border-1 brdr-color">
            <i class="pi pi-fw pi-user text-4xl font-bold"></i>
        </div>
    }
  </div>

  @if (layoutService.superuser) {
    <div class="notification-icon" (click)="notification()">
      <i class="pi pi-bell" style="font-size:1.5rem"></i>
    </div>
  }
</header>
