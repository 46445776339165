import { Component, OnInit, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from "./header/header.component";
import { TechnicianLayoutService } from './service/technician-layout.service';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { ApruvRateService, TechnicianService, TechOverviewResponse } from '@apruv-client/services';
import {LayoutService} from "../layout/service/layout-service.service";
import {parseCompanyIds} from "../shared/utils/utils";

@Component({
  selector: 'app-technician',
  standalone: true,
  imports: [CommonModule, RouterOutlet, FooterComponent, HeaderComponent, ToastModule],
  templateUrl: './technician.component.html',
  styleUrl: './technician.component.scss',
})
export class TechnicianComponent implements OnInit, AfterViewInit {
  isHeader: boolean = true;
  isFooter: boolean = true;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private technicianLayoutService: TechnicianLayoutService,
              private messageService: MessageService,
              private technicianService: TechnicianService,
              private layoutService: LayoutService,
              private apruvRateService: ApruvRateService
  ) {
    this.technicianLayoutService.updateUserDetails();
    this.route.url.subscribe((url) => {
      this.isHeader = true;
      this.isFooter = true;
      const page = this.router.url.split('/')[2];
      if(page == 'profile' || page == 'profile-details' || page == 'alert'){
        this.isHeader = false;
      }
      if(page == 'overview' || page == 'alert'){
        this.isFooter = false
      }
    });
  }

  ngOnInit(): void {
    this.getTechOverview();
    this.getConfig();
  }

  ngAfterViewInit(): void {
    if(!this.technicianLayoutService.getCookie('hideBanner')){
      this.openBanner();
    }
  }

  openBanner() {
    this.messageService.add({ key: 'banner', sticky: true, severity: 'custom', summary: 'Tip Payout Update', detail: 'Starting December 10th, tips received will automatically be deposited into the bank account linked to your Apruv Profile within 2–3 business days. You will not be required to hit the cash out option.' });
  }

  gotoAlert() {
    this.messageService.clear();
    this.router.navigateByUrl('/technician/alert');
  }

  getTechOverview() {
    const id = this.technicianLayoutService.technicianProfile!.sub;
    this.technicianService.getTechOverview(id).subscribe({
      next: (res: TechOverviewResponse) => {
        this.technicianLayoutService.techOverview = res;
      },
      error: (error: any) => {
        console.log(error)
      }
    })
  }

  getConfig() {
    if(this.layoutService.locationSelected?.Id){
      this.apruvRateService.getConfig(this.layoutService.companySelected!.id, this.layoutService.locationSelected.Id as number).subscribe({
        next: (response) => {
          this.technicianLayoutService.isTip = response.EnableTipping;
        },
        error: (error) => {
        }
      })
    }
  }

  doNotShow(){
    this.technicianLayoutService.setCookie('hideBanner', 'true', 365);
    this.messageService.clear();
  }
}
