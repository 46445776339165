import { DateTime } from "luxon";

export interface CompanyList {
    Id: number;
    Name: string;
  }

export interface LocationList {
  Id: number | string | undefined;
  FlId: number;
  Name: string;
}

export interface Location {
  ID: number,
  CompanyID: number,
  Name: string,
  Active: boolean,
  ApruvVerify: boolean,
  ApruvRate: boolean,
  ApruvActions: boolean,
  ApruvAlign: boolean,
  DateCreated: string,
  DateModified: string,
  GoogleLink: string,
  GoogleData: string
}

export interface LocationRequest {
  Name: string;
  Active: boolean;
  ApruvVerify: boolean;
  ApruvRate: boolean;
  ApruvActions: boolean;
  ApruvAlign: boolean;
  GoogleLink: string;
  GoogleData: string;
}

export interface locationListResponse {
  locations: Location[];
}

export interface TechLocation {
  name: string;
  location_id: number;
}

export interface locationsResponse {
  locations: LocationList[];
}

  export interface HLICompanyList {
    Id: number;
    Name: string;
    ApiToken: string;
  }

  export interface HLCompanyList {
    Id: string;
    Name: string;
  }

  export interface CompanyData {
    Id: number;
    Name: string;
    Address: string;
    City: string;
    State: string;
    Country: string;
    PostalCode: string;
    Phone: string;
    Website: string;
    UrlAlias: string;
    CompanyImage: string;
    DateCreated: Date;
    DateModified: Date;
  }

  export interface CompanyEdit {
    Id: number;
    Name: string;
    Address: string;
    City: string;
    State: string;
    Country: string;
    PostalCode: string;
    Phone: string;
    UrlAlias: string;
    Website: string;
  }

  export interface CompanySave {
    Name: string;
    Address: string;
    City: string;
    State: string;
    Country: string;
    PostalCode: string;
    Phone: string;
    UrlAlias: string;
    Website: string;
  }

  export interface IntegrationData {
    company_integration_id: number;
    integrations_id: number;
    integrations_name: string;
    integration_data_id: number;
    integration_active: boolean;
  }

  export interface UserData {
    Id: number;
    Firstname: string;
    Lastname: string;
    Email: string;
    RoleId: number;
    Role: string;
  }

  export interface CompaniesResponse {
    companies: CompanyList[];
  }

  export interface CompaniesAPIResponse {
    companies: HLICompanyList[];
  }

  export interface CompanyResponse {
    companyData: CompanyData;
    integrationData: IntegrationData[];
    companyUsers: UserData[];
  }

  export interface CompanyEditResponse {
    companyData: boolean;
  }

  export interface CompanySaveResponse {
    success: boolean;
    cid: number;
  }

  export interface DashboardData {
    companies: number;
    integrations: number;
    users: number;
  }

  export interface Location {
    id: number;
    field_routes_id: number;
    name: string;
    status: boolean;
    api_key: string;
    api_token: string;
    hl_api_token: string;
    google_link: string;
  }

  export interface FieldRoutes {
    Id: number;
    ApiBaseUrl: string;
    GlobalKeyToken: boolean;
    GlobalApiKey: string;
    GlobalApiToken: string;
    HighLevelLocationId: string;
    GoogleReviewUrl: string;
  }

  export interface FieldRoutesAdd {
    ApiBaseUrl: string;
    GlobalKeyToken: boolean;
    GlobalApiKey: string;
    GlobalApiToken: string;
    HighLevelLocationId: string;
    GoogleReviewUrl: string;
  }

  export interface Locations {
    Id: number;
    FieldRoutesLocationId: number;
    Name: string;
    Active: boolean;
    ApiKey: string;
    ApiToken: string;
    HlToken: string;
    GoogleLink: string;
    HlLocationId: string;
    HighLevelLocationId?: string;
    GoogleReviewUrl: string;
  }

  export interface LocationIntegration {
    ID: number;
    LocationID: number;
    IntegrationID: number;
    CompanyIntegrationID: number;
    IsActive: boolean;
    DateCreated: string;
    DateModified: string;
    LocationName: string;
    ApiKey: string;
    ApiToken: string;
    HighLevelToken: string;
    HighLevelLocationId: string;
    IntegrationData: any;
  }

  export interface LocationIntegrationAdd {
    ID?: number;
    IntegrationID: number;
    CompanyIntegrationID?: number;
    LocationID: number;
    IsActive: boolean;
    ApiKey: string;
    ApiToken: string;
    HighLevelToken: string;
    IntegrationData: any;
  }

  export interface IntegrationDetails {
    CompanyId:number;
    Id: number;
    IntegrationId: number;
    IntegrationData: {
      api_base_url: string;
      global: boolean;
    }
    GlobalApiKey: string;
    GlobalApiToken: string;
  }

  export interface IntegrationDetailsAdd {
    CompanyID?: number;
    globalApiKey: string;
    globalApiToken: string;
    IntegrationData: {
      api_base_url: string;
      global: boolean;
    }
  }

  export interface FieldRoutesResponse {
    FieldRoutes: FieldRoutes;
    Locations: Locations[];
  }

  export interface LocationIntegrationAddResponse {
    CompanyIntegration: IntegrationDetailsAdd;
    LocationIntegrations: LocationIntegrationAdd[];
  }

  export interface LocationIntegrationResponse {
    CompanyIntegration: IntegrationDetails;
    LocationIntegrations: LocationIntegration[];
  }

  export interface FieldRoutesAddResponse {
    FieldRoutes: FieldRoutesAdd;
    Locations: Locations[];
  }

  export interface IntegrationsList {
    Id: number;
    Name: string;
  }

  export interface IntegrationsListResponse {
    integrations: IntegrationsList[];
  }

  export interface Integration {
    Id: number;
    Name: string;
    Status: string;
  }

  export interface CompanyIntegration {
    company_integration_id: number;
    status: boolean;
  }

  export interface PayoutData {
    payoutId: number;
    technicianId: number;
    userId: number;
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
    paymentMethod: string;
    paid: boolean;
    payoutAmount: number;
    dateCreated: Date;
    datePaid: Date;
  }

export interface PayoutDataResponse {
  payout_data: PayoutData[];
}

export interface BankInfo {
  accountNumber: number;
  routingNumber: number;
}

export interface MarkAsPaidResponse {
  success: boolean;
}

export interface JwtPayload {
  user_id: number;
  email: string;
  role_id: number;
  company_id: number;
  exp: number;
}

export interface CompanyDashData {
  total_tips: number;
  total_tip_value: string;
  total_review_requests: number;
  google_reviews: number;
  google_rating: string;
  apruv_ratings: number;
  review_link_clicks: number;
  positive_apruv_reviews: number;
  negative_apruv_reviews: number;
  credit_card_amount: string;
  paypal_amount: string;
  venmo_amount: string;
  google_review_matches?: number | string;
}

export interface ReviewsDashboardData {
  customer_id?: number;
  fr_id?: number;
  location_name?: string;
  customer_name?: string;
  technician_name?: string;
  rating?: number;
  comments?: string;
  google_rating?: number;
  google_review?: number;
  appointment_date?: string | null;
}

export interface ReviewsData {
  location_name: string;
  date: Date;
  reviews: number;
}

export interface UserList {
  keycloak_id?: string
  id?: number;
  user_id?: number;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  location_name: string;
  role: string;
  full_name?: string;
  location_list?: UserLocationList[]
  location_names?: any
  companyId?: number;
  old_email?: string;
  status?: boolean;
}

export interface TechUserList {
  keycloak_id: string
  first_name: string
  last_name: string
  email: string
  phone: string
  location_name: any
  full_name?: string;
  old_email?: string;
  hasKeycloakId?: boolean;
  technician_ids: number[] | number;
}

export interface UserLocationList {
  location_id: number;
  status: boolean;
}

export interface DropdownOptions {
  label: string;
  value: number | string | boolean;
  valueStr?: string;
}

export interface UserLocation {
  location: string;
  location_id: number;
  status: string;
}

export interface CrmUsers {

}

export interface DupUserLocation {
  id: number;
  location: string;
  name: string;
}

export interface DupUserInfo {
  id: number
  user_id: number
  user_name: string
  user_email: string
  user_locations: string
  user_phone: string
  technician_id: number
  technician_name: string
  technician_email: string
  technician_location: string
  technician_phone: string
  technician_phone_input?: string
}

export interface EmailSubscription {
  id: number
  user_id: number
  name: string
  email: string
  // role: string
  company_id: number
  location_id: number
  location: string
  active: boolean
  subscription_type: string
}

export interface EmailSubscriptionDTO {
  user_id: number
  first_name: string
  last_name: string
  location_id: number
  active: boolean
  email: string
  subscription_type: string
}

export interface UserProfile {
  firstname: string
  lastname: string
  email: string
  phone: string
}


export interface TechnicianDetail {

}

export interface UserInfo {
  CompanyID: number;
  ID: number;
  IsSuperUser: boolean;
  RoleID: number;
  KeycloakID: string;
}

export interface BugInfo {
  bug_id: number
  keycloak_id: string
  first_name: string
  last_name: string
  email: string
  phone: string
  bug: string
  active: boolean
  reported_date: string
  date_modified: string
}

export interface Client {
  customer_id: string
  first_name: string
  last_name: string
  full_name?: string
  location_id?: number
  email: string
  primary_phone: string
  secondary_phone: string
  address: string
  city: string
  state: string
  zip: string
  subscription_list: Subscription[]
  last_date_modified: string | null
  apay: boolean
}

export interface Subscription {
  initial_quote: number
  initial_price: number
  agreement_length: number
  annual_recurring_services_desc: string
  recurring_charge: number
  id: string | number
  subscription_id: string | number
  service_type: string
  date_added: string
  contract_added: string
  status: string | boolean
  verify_information: VerifyInfo[]
}

export interface VerifyInfo {
  id: number
  status: string
  video_path: string
  date_created: string
}

export interface VerifyCodeResponse {
  company_id: number
  customer_id: number
  passcode: string
  subscription_id: string
  first_name: string
  last_name: string
  location_id: number
}

export interface CompleteVerifyRequest {
  customer_id: number
  company_id: number
  subscription_id: number
  location_id: number
  video_path: string
  language: string
  status: string
  questionType?: any[]
}

export interface VerifyConfig {
  default_location: number;
  role_id: number;
  global_key: boolean;
  show_apruv: boolean;
  show_admin: boolean;
}

export interface DetailedLogApiResponse {
  detailed_logs: DetailedLog[]
}

export interface DetailedLog {
  id: number
  customer_id: number
  sales_rep: string
  customer_name: string
  location_name: string
  status: string
  fee: number
  balance: string
  billed: boolean
  video: string
  date_created: string
}

export interface DetailedLogApiResponse {
  completedVerifications: CompletedVerifications[]
}

export interface CompletedVerifications {
  [key:string]: number
}

export type VerifyDashboardApiResponse = VerifyCompany[];

export interface VerifyCompany {
  company_id: number
  company_name: string
  total: number
  locations: VerifyLocation[]
}

export interface VerifyLocation {
  location_id: number
  location_name: string
  total: number
  sales_rep: SalesRep[]
}

export interface SalesRep {
  id: number
  total: number
}
export interface Message {
  content: string;
  cContent: string;
  type: string;
}

export interface DialogStep {
  audible_response_required: boolean;
  audioMessage: string[];
  mMessage: string[];
  new_screen: boolean;
  next_step: number;
  response_required: boolean;
  step: number;
  variables: string[];
  conditions: {
    yes?: ConditionDetail;
    no?: ConditionDetail;
    [key: string]: ConditionDetail | undefined;  // Optionally, allow other keys with similar structure
  };
  audioFileCount: number;
  question_type: QuestionType;
  audioFiles: string[];
}

export interface QTMessage {
  text: string;
  audioFiles: string[];
}

export interface ResponseDetail {
  no?: QTMessage[];
  yes?: QTMessage[];
}

export interface QuestionType {
  [key: string]: ResponseDetail;
}

interface ConditionDetail {
  next_step: number;
}

export interface DialogWorkflow {
  Address: string;
  AppointmentId: number;
  City: string;
  DialogWorkflow: DialogStep[];
  STT: boolean;
  Email: string;
  EndTime: string;
  FirstName: string;
  LastName: string;
  LocationID: number;
  Phone: string;
  SecondaryPhone: string;
  ServiceDate: string;
  StartTime: string;
  State: string;
  StreamingOptions: any; // Define more specifically if needed
  Subscription: {
    AgreementLength: string;
    AnnualRecurringServicesDesc: string;
    FollowupService: number;
    InitialDiscount: string;
    InitialPrice: string;
    InitialQuote: string;
    RecurringCharge: string;
  };
  Zip: string;
}

export interface VerifyFlow {
  currentStep: number;
  dialogWorkflow: DialogWorkflow;
  preloaded: boolean;
}


export interface VerificationsApiResponse {
  verifications: Verifications
}

export interface Verifications {
  total_records: VerificationsTotalRecords[]
  locations: VerificationLocation[]
}

export interface VerificationsTotalRecords {
  status: string
  total_count: number
  percentage: number
} 

export interface VerificationLocation {
  location_id: number
  location_name: string
  statuses: VerificationsTotalRecords[]
}


export interface CategoryPoint {
    company_id: number
    category: string
    points: number
}

export interface LeadTechDashData {
  avg_time: string
  productivity_top: number
  productivity_bottom: number
  driving_score: number
  reservice_number: number
  reservice_percent: number
  rate_csat: number
  tech_retention: number
}

export interface LeadTechDashDataParam {
  company_id: number
  location_ids: number[]
  technician_ids: number[]
  start_date: DateTime
  end_date: DateTime
}

export interface LeadSalesDashDataParam {
  company_id: number
  location_ids: number[]
  start_date: DateTime
  end_date: DateTime
}

export interface LeadSalesResponseData {
  lead_sales: LeadSales
}

export interface LeadSales {
  sold_subscription: number
  average_contract_value: string
  total_contract_value: string
  category_sales: CategorySale[]
}

export interface CategorySale {
  category: string
  total: number
  avg_cv: string
  total_cv: string
}

export interface LeadRoutingResponseData {
  lead_routing: LeadRouting
}
export interface LeadRouting {
  routes_under_goal: number
  average_daily_distance: number
}

export interface Root {
  lead_sales: LeadSales
}
export interface LeadOwnerSubResponseData {
  lead_owner_sub: LeadOwnerSub
}

export interface LeadOwnerSub {
  total_active_subscriptions: number
  category_counts: CategoryCount[]
  total_sales: number
  total_cancelled: number
  total_referrals: number
  collected_ar: number
}

export interface CategoryCount {
  category: string
  total: number
}

export interface SalesRepDashDataApiResponse {
  sales_rep_verify_dashboard: SalesRepVerifyDashboard
}

export interface SalesRepVerifyDashboard {
  totals: Total[]
  customers: CustomerData[]
}

export interface Total {
  status: string
  total_count: number
}

export interface CustomerData {
  status: string
  customer: Customer
}

export interface Customer {
  customer_id: number
  customer_name: string
  phone: string
  date_added: string
  status: string
  location_id?: number
  full_name?: number
  address?: number
  subscription_list?: any
  last_date_modified?: number
  apay?: boolean;
}

export interface BillingHistoryApiResponse {
  billing_history: BillingHistory[]
}

export interface BillingHistory {
  transaction_id: string
  transaction_date: string
  amount: number
  payment_status: string
}
