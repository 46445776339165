<div class="page-section">
    <div class="page-section-ref">
        @if (isHeader) {
            <div class="p-top-sticky">
              <apruv-header></apruv-header>
            </div>
        }
        <router-outlet></router-outlet>
        @if (isFooter) {
            <div class="p-bottom-sticky">
                <div class="d-flex flex-column">
                    <apruv-dashboard-footer></apruv-dashboard-footer>
                </div>
            </div>
        }
    </div>
</div>
<p-toast
position="top-center"
key="banner"
[baseZIndex]="5000">
    <ng-template let-message pTemplate="headless" let-closeFn="closeFn">
        <section class="alrt">
            <div class="flex flex-row justify-content-between w-full">
                <div class="alrt-icon">
                    <i class="pi pi-info-circle text-2xl"></i>
                </div>

                <div>
                    <i class="pi pi-times p-error cursor-pointer" style="font-size: 1.5rem" (click)="closeFn($event)"></i>
                </div>
            </div>
            <div class="flex flex-column gap-1">
                <h3 class="m-0 font-semibold text-lg text-red-700">{{ message.summary }}</h3>
                <p class="m-0 font-medium text-lg text-red-700">
                    {{ message.detail }}
                </p>
            </div>
            <div class="flex flex-row justify-content-between w-full">
                <h3 class="m-0 text-red-600 font-semibold text-lg cursor-pointer" (click)="gotoAlert()">Learn more</h3>
                <h3 class="m-0 text-red-600 font-semibold text-lg cursor-pointer" (click)="doNotShow()">Don't see again</h3>
            </div>
        </section>
    </ng-template>
</p-toast>
<p-toast />