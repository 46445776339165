import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { LayoutService } from '../../layout/service/layout-service.service';
import { TechnicianLayoutService } from '../service/technician-layout.service';

@Component({
  selector: 'apruv-dashboard-footer',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
})
export class FooterComponent {
  active = '';

  constructor(private router: Router, private route: ActivatedRoute, public layoutService: LayoutService, public techLayoutService: TechnicianLayoutService) {
    this.route.url.subscribe((url) => {
      const page = this.router.url.split('/')[2];
      this.active = '';
      if(!page){
        this.active = 'home';
      }
      else if(page == 'wallet'){
        this.active = 'wallet';
      }
      else if(page == 'feedback'){
        this.active = 'feedback';
      }
      else if(page == 'rank'){
        this.active = 'rank';
      }
      else if(page == 'service'){
        this.active = 'service';
      }
    });
  }

  selectTab(tab: string){
    this.active = tab;

    if(tab == 'home'){
      this.router.navigateByUrl('/technician');
    } else{
      this.router.navigateByUrl(`/technician/${tab}`);
    }
  }
}
