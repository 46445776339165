import { APP_INITIALIZER, ApplicationConfig, Provider, provideZoneChangeDetection } from '@angular/core';
import { provideRouter, Router } from '@angular/router';
import { appRoutes } from './app.routes';
import { KeycloakBearerInterceptor, KeycloakEvent, KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { environment } from "@env";
import { catchError, of, switchMap, tap } from 'rxjs';
import { FrontendCompanyService, FrontendRoutesService, FrontendUserService } from "@apruv-client/services";
import { LayoutService } from './layout/service/layout-service.service';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from "primeng/dynamicdialog";
import { provideAnimations } from '@angular/platform-browser/animations';
import { ApiConfigInterceptor } from './shared/services/api-config.interceptor';
import { jwtDecode } from 'jwt-decode';
import { parseCompanyIds } from './shared/utils/utils';
import { provideNgxMask } from "ngx-mask";
import {APP_BASE_HREF} from "@angular/common";


function initializeKeycloak(
  keycloak: KeycloakService,
  router: Router,
  userService: FrontendUserService,
  layoutService: LayoutService
): () => Promise<boolean> {
  return async () => {
    const authenticated = await keycloak.init({
      config: {
        url: environment.keyCloakUrl,
        realm: environment.keyCloakRealm,
        clientId: environment.keyCloakClientId,
      },
      initOptions: {
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
      },
      enableBearerInterceptor: true,
      bearerExcludedUrls: [],
    }).catch((error) => {
      console.error('Keycloak initialization error:', error);
      return false;
    });

    if (authenticated) {
      await fetchDataAfterAuth(layoutService, keycloak);
    }

    // Listen for re-login events to refetch data
    keycloak.keycloakEvents$.subscribe(async (event: KeycloakEvent) => {
      if (event.type === KeycloakEventType.OnAuthSuccess) {
        await fetchDataAfterAuth(layoutService, keycloak);
      }
    });

    // Additional user initialization if authenticated
    return true;
  };
}

async function fetchDataAfterAuth(layoutService: LayoutService, keycloak: KeycloakService): Promise<void> {
  try {
    const token = await keycloak.getToken();
    const decodedToken: any = jwtDecode(token!);
    const defaultCompanyIds = parseCompanyIds(decodedToken?.orgs)
    layoutService.permissions = decodedToken?.realm_access?.roles
    layoutService.superuser = decodedToken?.realm_access?.roles?.includes('super_user');
    const defaultLocations = decodedToken.defaultLocations ? JSON.parse(decodedToken.defaultLocations) : null;
    if(defaultLocations && defaultLocations.length) {
      layoutService.setCompanyId(defaultLocations[0].company_id)
      layoutService.setLocationId(defaultLocations[0].location_id)
    } else {
      const defaultIds = extractCompanyAndLocation(decodedToken.orgs)[0];
      layoutService.setCompanyId(defaultIds.company)
      layoutService.setLocationId(defaultIds.location)
    }
    await layoutService.fetchInitialData(defaultCompanyIds[0]).toPromise();
    layoutService.redirectUser(decodedToken);
    layoutService.isRedirect = true;
  } catch (error) {
    console.error('Error fetching initial data:', error);
  }
}

function extractCompanyAndLocation(data: string[]): { company: number; location: number }[] {
  return data
    .filter((item) => item.includes("company") && item.includes("location"))
    .map((item) => {
      const companyMatch = item.match(/company:(\d+)/);
      const locationMatch = item.match(/location:(\d+)/);
      if (companyMatch && locationMatch) {
        return {
          company: parseInt(companyMatch[1], 10),
          location: parseInt(locationMatch[1], 10),
        };
      }
      return null;
    })
    .filter((obj): obj is { company: number; location: number } => obj !== null); // Remove any null values
}

function initializeData(layoutService: LayoutService, keycloak: KeycloakService): () => Promise<void> {
  return async () => {
    if (!keycloak.isLoggedIn()) {
      console.log('User not logged in. Skipping data fetch.');
      return;
    }

    await new Promise<void>((resolve) => {
      keycloak.keycloakEvents$.subscribe((event: KeycloakEvent) => {
        if (event.type === KeycloakEventType.OnAuthSuccess || event.type === KeycloakEventType.OnAuthRefreshSuccess) {
          resolve();
        }
      });
    });

    try {
      const token = await keycloak.getToken();
      const decodedToken: any = jwtDecode(token!);
      const defaultCompanyIds = parseCompanyIds(decodedToken?.orgs)
      layoutService.permissions = decodedToken?.realm_access?.roles
      layoutService.superuser = decodedToken?.realm_access?.roles?.includes('super_user');
      await layoutService.fetchInitialData(defaultCompanyIds[0]).toPromise();
    } catch (error) {
      console.error('Error fetching initial data:', error);
    }
  };
}

// Provider for Keycloak Bearer Interceptor
const KeycloakBearerInterceptorProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: KeycloakBearerInterceptor,
  multi: true
};

// Provider for Keycloak Initialization
const KeycloakInitializerProvider: Provider = {
  provide: APP_INITIALIZER,
  useFactory: initializeKeycloak,
  multi: true,
  deps: [KeycloakService, Router, FrontendUserService, LayoutService]
}

const DataInitializerProvider: Provider = {
  provide: APP_INITIALIZER,
  useFactory: initializeData,
  deps: [LayoutService, KeycloakService],
  multi: true
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(appRoutes),
    provideHttpClient(),
    provideAnimations(),
    provideNgxMask(),
    HttpClient,
    provideHttpClient(withInterceptorsFromDi()), // Provides HttpClient with interceptors
    KeycloakInitializerProvider, // Initializes Keycloak
    KeycloakBearerInterceptorProvider, // Provides Keycloak Bearer Interceptor
    DataInitializerProvider,
    { provide: HTTP_INTERCEPTORS, useClass: ApiConfigInterceptor, multi: true },
    KeycloakService,
    FrontendUserService,
    FrontendCompanyService,
    FrontendRoutesService,
    MessageService,
    ConfirmationService,
    DialogService
  ],
};
