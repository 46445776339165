<div class="page-section auth-section">
    <div class="page-section-ref p-2">
        <div class="p-top-sticky">
          <div class="flex align-items-center justify-content-center">
            <div class="text-center">
              <svg id="Layer_1" class="cursor-pointer" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" height="88px" width="148px" viewBox="0 0 250 150">
                <defs>
                  <style>
                    .cls-1 {
                      fill: #fff;
                    }
                  </style>
                </defs>
                <path class="cls-1" d="M45.15,96.7c-.45,.66-1.05,.99-1.79,.99H2.19c-.78,0-1.38-.33-1.79-.98-.45-.66-.52-1.33-.19-2.03l1.92-4.48c.16-.41,.42-.74,.77-.98,.35-.24,.75-.37,1.21-.37h28.81l-1.85-4.46-8.28-19.81-8.28,19.81c-.16,.41-.42,.73-.77,.96-.35,.23-.75,.34-1.21,.34H7.32c-.78,0-1.4-.33-1.85-.99-.41-.62-.47-1.3-.19-2.04l12.55-29.47c.16-.45,.42-.79,.77-1.02,.35-.23,.75-.34,1.21-.34h5.93c.95,0,1.61,.45,1.98,1.36l17.62,41.48c.29,.66,.23,1.34-.19,2.04Z"/>
                <path class="cls-1" d="M88.86,56.02c3,2.84,4.51,6.61,4.51,11.31s-1.51,8.47-4.51,11.31c-2.93,2.76-6.88,4.14-11.87,4.14h-11.81v12.61c0,.58-.22,1.08-.65,1.52-.43,.43-.93,.65-1.5,.65h-4.66c-.57,0-1.08-.22-1.51-.65-.43-.43-.64-.94-.64-1.52v-19.16c0-.58,.22-1.08,.65-1.52,.43-.43,.94-.65,1.52-.65h18.3c2.76,0,4.74-.59,5.93-1.78,1.19-1.07,1.79-2.71,1.79-4.92s-.6-3.77-1.79-4.92c-1.28-1.19-3.26-1.78-5.93-1.78h-18.3c-.58,0-1.08-.22-1.52-.65-.43-.44-.65-.94-.65-1.53v-4.48c0-.58,.22-1.09,.65-1.52,.43-.44,.94-.65,1.51-.65h18.64c4.9,0,8.85,1.4,11.85,4.2Z"/>
                <path class="cls-1" d="M143.75,94.23c.45,.74,.47,1.48,.06,2.23-.41,.74-1.03,1.11-1.85,1.11h-5.63c-.78,0-1.38-.33-1.79-.99l-9.42-14.9h-11.04v13.72c0,.58-.22,1.08-.65,1.52-.43,.43-.93,.65-1.5,.65h-4.66c-.57,0-1.08-.22-1.51-.65-.43-.43-.64-.94-.64-1.52v-20.39c0-.58,.22-1.08,.65-1.52,.43-.43,.94-.65,1.52-.65h19.04c3.63,0,6.04-1.02,7.23-3.06,.41-.65,.62-1.59,.62-2.82,0-1.92-.64-3.43-1.92-4.53-1.32-1.18-3.22-1.77-5.69-1.77h-19.29c-.58,0-1.08-.22-1.52-.65-.43-.44-.65-.94-.65-1.53v-4.48c0-.58,.22-1.09,.65-1.52,.43-.44,.94-.65,1.51-.65h19.81c4.77,0,8.62,1.36,11.54,4.08,3,2.72,4.51,6.41,4.51,11.07,0,6.1-2.56,10.28-7.67,12.55-.16,.08-.33,.15-.49,.22s-.35,.13-.55,.22l9.33,14.28Z"/>
                <path class="cls-1" d="M172.56,89.09c.49,.17,.89,.44,1.17,.84,.29,.39,.43,.83,.43,1.33v4.76c0,.66-.27,1.22-.8,1.67-.41,.33-.87,.49-1.36,.49-.21,0-.35-.02-.43-.06-3.83-.74-7.13-2.51-9.89-5.32-3.71-3.75-5.56-8.74-5.56-14.96v-23.8c0-.62,.22-1.14,.65-1.58,.43-.43,.94-.65,1.51-.65h4.76c.62,0,1.14,.22,1.58,.65,.43,.43,.65,.96,.65,1.58v23.92c0,3.92,1.11,6.9,3.34,8.96,1.15,1.07,2.47,1.79,3.96,2.16Zm21.33-37.28c.62,0,1.14,.22,1.58,.65,.43,.43,.65,.96,.65,1.58v23.8c0,6.22-1.85,11.21-5.56,14.96-2.76,2.8-6.06,4.57-9.89,5.32-.08,.04-.23,.06-.43,.06-.49,0-.95-.16-1.36-.49-.54-.41-.8-.99-.8-1.73v-4.76c0-.45,.14-.88,.43-1.27,.29-.39,.68-.67,1.17-.84,1.57-.45,2.88-1.17,3.96-2.16,2.23-1.98,3.34-4.97,3.34-8.96v-23.92c0-.62,.22-1.14,.65-1.58,.43-.43,.96-.65,1.58-.65h4.7Z"/>
                <path class="cls-1" d="M249.58,52.81c.45,.62,.54,1.3,.25,2.04l-16.94,41.85c-.21,.45-.49,.79-.83,1.02-.35,.23-.75,.34-1.21,.34h-5.44c-1.03,0-1.71-.45-2.04-1.36l-16.94-41.85c-.33-.7-.27-1.38,.18-2.04,.45-.66,1.05-.99,1.79-.99h20.65c.58,0,1.08,.22,1.52,.65,.43,.43,.65,.96,.65,1.58v4.51c0,.62-.22,1.14-.65,1.58-.43,.43-.94,.65-1.52,.65h-10.39l9.46,23.8,12.42-31.4c.33-.91,1.01-1.36,2.04-1.36h5.19c.78,0,1.38,.33,1.79,.99Z"/>
              </svg>
            </div>
          </div>
        </div>

        <div class="flex align-items-center justify-content-between flex-column">
            <div class="flex flex-column align-items-center w-full">
                <h6 class="text-white font-semibold text-center">To register with Apruv, please submit the following:</h6>
                <p-progressSpinner *ngIf="isLoading"></p-progressSpinner>
                <div class="w-full px-4" *ngIf="!isLoading">
                    <form action="" [formGroup]="registrationForm">
                        <div class="flex flex-column">
                          <div class="mb-2 pt-2">
                            <label class="text-600 font-medium text-white">Registration Code</label>
                          </div>
                            <span class="p-input-icon-left w-full mb-3">
                                <i class="pi pi-lock"></i>
                                <input id="registration_code" type="text" pInputText
                                    class="w-full md:w-25rem borderless brdr-color py-3 "
                                    formControlName="registration_code" />
                            </span>
                          <div *ngIf="registrationForm.get('registration_code')?.invalid && (registrationForm.get('registration_code')?.dirty || registrationForm.get('registration_code')?.touched)"
                               class="p-error mt-2 pl-2">
                            <div *ngIf="registrationForm.get('registration_code')?.errors?.['required']">
                              Registration Code is required.
                            </div>
                          </div>

                          <div class="mb-2 pt-2">
                            <label class="text-600 font-medium text-white">First Name:</label>
                          </div>
                          <span class="p-input-icon-left w-full mb-3">
                                <i class="pi pi-user"></i>
                                <input id="first_name" type="text" pInputText
                                       class="w-full md:w-25rem borderless brdr-color py-3 "
                                       formControlName="first_name" />
                            </span>
                          <div *ngIf="registrationForm.get('first_name')?.invalid && (registrationForm.get('first_name')?.dirty || registrationForm.get('first_name')?.touched)"
                               class="p-error mt-2 pl-2">
                            <div *ngIf="registrationForm.get('first_name')?.errors?.['required']">
                              First Name is required.
                            </div>
                          </div>

                          <div class="mb-2 pt-2">
                            <label class="text-600 font-medium text-white">Last Name:</label>
                          </div>
                          <span class="p-input-icon-left w-full mb-3">
                                <i class="pi pi-user"></i>
                                <input id="last_name" type="text" pInputText
                                       class="w-full md:w-25rem borderless brdr-color py-3 "
                                       formControlName="last_name" />
                            </span>
                          <div *ngIf="registrationForm.get('last_name')?.invalid && (registrationForm.get('last_name')?.dirty || registrationForm.get('last_name')?.touched)"
                               class="p-error mt-2 pl-2">
                            <div *ngIf="registrationForm.get('last_name')?.errors?.['required']">
                              Last Name is required.
                            </div>
                          </div>

                          <div class="mb-2 pt-2">
                            <label class="text-600 font-medium text-white">Phone Number:</label>
                          </div>
                          <div>
                            <span class="p-input-icon-left w-full mb-3">
                              <i class="pi pi-phone"></i>
                              <input pInputText mask="(000) 000-0000" class="w-full md:w-25rem borderless brdr-color py-3 " type="text" id="phone" formControlName="phone_number" inputmode="numeric"/>
                            </span>
                          </div>
                          <div *ngIf="registrationForm.get('phone_number')?.invalid && (registrationForm.get('phone_number')?.dirty || registrationForm.get('phone_number')?.touched)"
                               class="p-error mt-2 pl-2">
                            <div *ngIf="registrationForm.get('phone_number')?.errors?.['required']">
                              Phone Number is required.
                            </div>
                          </div>

                          <div class="mb-2 pt-2">
                            <label class="text-600 font-medium text-white">Email:</label>
                          </div>
                          <span class="p-input-icon-left w-full mb-3">
                                <i class="pi pi-envelope"></i>
                                <input id="email" type="text" pInputText
                                       class="w-full md:w-25rem borderless brdr-color py-3 "
                                       formControlName="email" />
                            </span>
                          <div *ngIf="registrationForm.get('email')?.invalid && (registrationForm.get('email')?.dirty || registrationForm.get('email')?.touched)"
                               class="p-error mt-2 pl-2">
                            <div *ngIf="registrationForm.get('email')?.errors?.['required']">
                              Email is required.
                            </div>
                            <div *ngIf="registrationForm.get('email')?.errors?.['pattern']">
                              Email has an incorrect format. Please check again.
                            </div>
                          </div>

                          <div class="mt-1 pt-2">
                            <label class="text-600 font-medium text-white">Password</label>
                          </div>
                          <div class="flex flex-column">
                            <span class="p-input-icon-left w-full mt-2">
                                <i class="pi pi-lock z-2"></i>
                                <p-password id="password" formControlName="password"
                                            [inputStyle]="{ paddingLeft: '2.5rem' }"
                                            inputStyleClass="w-full md:w-25rem text-color-secondar brdr-color py-3 "
                                            [toggleMask]="true"></p-password>
                            </span>
                            <div class="p-error mt-2"
                                 *ngIf="registrationForm.get('password')?.invalid && (registrationForm.get('password')?.dirty || registrationForm.get('password')?.touched)">
                              <div *ngIf="registrationForm.get('password')?.errors?.['required']">
                                Password is required.
                              </div>
                              <div *ngIf="registrationForm.get('password')?.errors?.['minlength']">
                                Minimum of 8 characters are required for password
                              </div>
                            </div>

                            <div class="mt-4">
                              <span class="text-600 font-medium text-white">Confirm Password</span>
                            </div>
                            <span class="p-input-icon-left w-full mt-2">
                                <i class="pi pi-lock z-2"></i>
                                <p-password id="password2"
                                            formControlName="password2" (input)="passwordsMatch()"
                                            [inputStyle]="{ paddingLeft: '2.5rem' }"
                                            inputStyleClass="w-full md:w-25rem text-color-secondary brdr-color py-3"
                                            [toggleMask]="true" [feedback]="false"></p-password>
                            </span>
                            <div class="p-error mt-4"
                                 *ngIf="registrationForm.get('password2')?.invalid && (registrationForm.get('password2')?.dirty || registrationForm.get('password2')?.touched)">
                              <div *ngIf="registrationForm.get('password2')?.errors?.['required']">
                                Confirm password is required.
                              </div>
                            </div>
                            <div *ngIf="test" class="text-600 text-red-600 pb-4 ">Passwords and confirm password does not match</div>
                          </div>

                            <div class="flex flex-wrap gap-2 justify-content-between mt-4">
                                <button (click)="onSubmit()" class="p-button py-3 w-full flex justify-content-center text-lg"
                                              [class.disabled]="registrationForm.invalid || test">Submit</button>
                            </div>
                            <div class="mt-5">
                              <p class="text-center cursor-pointer">Already have an account? <a class="text-primary" (click)="goToLogin()">Login here</a></p>
                            </div>
                          <div *ngIf="errorMessage" class="p-error">{{errorMessage}}</div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="pb-4">
                <div class="flex justify-content-center my-5 gap-2">
                    Powered by <strong>Apruv</strong>
                </div>
            </div>
        </div>
    </div>
</div>

<p-dialog styleClass="no-header" [breakpoints]="{ '570px': '98vw' }" [style]="{ width: '70vw' }" [(visible)]="invalidLinkVisible" [modal]="true" [closable]="false" [resizable]="false" [draggable]="false">
  <h4 class="text-center mb-2 w-full">Invalid Registration Link</h4>
</p-dialog>

<p-dialog [header]="registrationError ? 'Registration Error' : ''" styleClass="{{registrationError ? '' : 'no-header'}}" [breakpoints]="{ '570px': '98vw' }" [style]="{ width: '70vw' }" [(visible)]="registrationSubmitted" [modal]="true" [closable]="registrationError ? true : false" [resizable]="false" [draggable]="false">
  <ng-container *ngIf="registrationError">
    <p>{{registrationError}}</p>
  </ng-container>
  <ng-container *ngIf="!registrationError">
    <h4 class="text-center mb-2 mt-5 w-full">Registration Sent</h4>
    <p>Please check your email inbox to verify your email.</p>
    <p>Or login here:
      <a href="https://app.apruv.io" target="_blank">app.apruv.io</a>
    </p>
  </ng-container>
</p-dialog>
